@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-dialog {
  @media (max-width: 767px) {
    height: 100vh;
    width: 100vw;
  }
}
.v-card {
  text-align: center;
  justify-content: center;
}
.v-card__title {
  display: inline-block;
  width: 100%;
  word-break: normal;
  line-height: 1.6rem;
}
.v-card__actions {
  > button {
    flex-basis: 50%;
    @include media('sm-and-up') {
      &:last-child {
        margin-left: 14px !important;
      }
    }
  }
}
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
