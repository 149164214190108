@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.range {
  font-size: 11px;
}
.day-headings {
  display: flex;

  font-size: 11px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 6px;
  border-bottom: 1px solid rgba(black, 0.15);

  > div {
    flex-basis: calc(100% / 7);
    text-align: center;
  }
}
.swiper {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
.swiper-bounds {
  width: calc(100% - 60px);
  margin-left: 30px;
  overflow-x: hidden;
}
.swiper-next,
.swiper-prev {
  position: absolute;
  z-index: 2;
  top: 47%;
  transform: translateY(-50%);
}
.swiper-prev {
  left: 0;
}
.swiper-next {
  right: 0;
}
.swiper-wrapper {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
.swiper-slide {
  display: flex;
  height: 50px;
}
.day {
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day__btn {
  aspect-ratio: 1;
  width: 80%;
  max-width: 36px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  .is-today & {
    background: $grey-200;
    color: $brand-red;
  }

  .is-selected & {
    background: $brand-red;
    color: white;
  }
}

$year: 11px;

.day__date {
  margin-bottom: -5px;

  .has-year & {
    font-size: $year;
  }

  .is-selected & {
    font-size: inherit;
  }
}

.day__month {
  font-size: 11px;
  margin-right: 2px;

  .is-selected & {
    display: none;
  }

  .has-year & {
    font-size: $year;
  }
}

.day__year {
  display: block;
  margin-top: -4px;
  .is-selected & {
    display: none;
  }

  font-size: $year;
}

.create-btn {
  display: inline-flex;
  align-items: center;
  padding: 10px;
}

.create-btn__icon {
  transform: translateY(-2px);
  height: 24px;
  width: 24px;
  margin-left: 8px;

  .v-icon {
    font-size: 24px;
  }
}
