@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.cards-group {
  display: flex;
  flex-flow: row wrap;
  gap: 1.75rem;
}

.card-wrapper ::v-deep {
  > .v-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .v-card__subtitle {
      align-items: center;
      display: flex;
      flex-grow: 1;
    }

    > .v-image {
      flex-grow: 0;
    }

    > .v-overlay {
      flex-grow: 0;
    }
  }
}

.home__top {
  @include media('xs-only') {
    background-color: #f7f7f7;
  }
}
